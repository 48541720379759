import React from 'react';
import {
  BrowserRouter as Router, Link, Route, Switch
} from "react-router-dom";
import "../src/assets/logo.png";
import './App.css';


import Footer from './components/Footer';
import NavTop from './components/NavTop';
import DatenschutzPage from './container/DatenschutzPage';
import ImpressumPage from './container/ImpressumPage';
import KontaktPage from './container/KontaktPage';
import ServicePage from './container/ServicePage';
import HomePage from './HomePage';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>

      <ScrollToTop />
      <NavTop />

      <div className="PageAnimation">
        <Switch>

          <Route component={HomePage} path="/" exact></Route>
          <Route path={["/online-foto-service", "/foto-service/", "/fotoservice/"]} component={ServicePage} />
          <Route path="/impressum/" component={ImpressumPage} />
          <Route path="/kontakt/" component={KontaktPage} />
          <Route path="/datenschutz/" component={DatenschutzPage} />
          <Route path="/">
            <div className="container">
              <h1>Diese Unterseite wurde nicht gefunden.</h1>
              <Link to="/"><button>Zur Startseite</button></Link>
            </div>
          </Route>
        </Switch>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
