import React from 'react';
import SubPageItem from './components/SubPageItem';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import GrillschuleImg from "./assets/grillschule.jpg";
import LadenImg from "./assets/laden.jpg";


function HomePage() {
  return (
    <div className="col-12">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Foto Halbich | Ihr Bilderprofi in Grünberg Portraitfotograf mit eigenem Farblabor. </title>
      </Helmet>


      {/* Bereich unten */}
      <div className="container mt-5">
        <h1 className='d-none'>Ihr Bilderprofi in Grünberg Portraitfotograf mit eigenem Farblabor.</h1>

        <div className="row">

          {/* Main Content */}
          <div className="col-12 col-lg-9 ph-5 mainstripe">
            <div className="main-content w-100 mb-5">



              {subPageItems.map((i, k) =>
                <SubPageItem  {...i} key={k} />
              )}



            </div>
          </div>



          {/* Sidebar */}
          <div className="col-12 col-lg-3 sidebar pt-5 pl-4">
            <div className="item">
              <h2>Online Bilderservice</h2>
              <p>Hier gehts zum Online Bilderservice </p>
              <Link to="/foto-service/"><button>Weiter</button></Link>
            </div>

            <div className="item">
              <h2>Öffnungszeiten</h2>
              <p><b>Mo - Fr:<br />9:00 - 18:00 Uhr</b></p>

              <p><b>Sa:<br />9:30 - 14:00 Uhr<br /></b><small>1. Samstag im Monat: 9:30 - 16:00 Uhr</small></p>

            </div>

            <div className="item">
              <h2>Kontakt</h2>
              <div className="d-flex flex-column">
                <p>Barfüßergasse 4<br />35305 Grünberg<br /><a href="https://goo.gl/maps/5Jcikh6pU5tf8SVA9" target="_blank">Google Maps</a></p>

                <p>
                  <a href="tel:49640190147">06401 90147</a><br />
                  <a href="mailto:bilder@foto-halbich.de">bilder@foto-halbich.de</a>
                </p>
              </div>
              <br />
            </div>

            <div className="item">
              <h2>Grillfachhandel und Grillschule</h2>
              <p><small>Schauen Sie gerne bei unserer <a target="_blank" href="http://gartenaroma.de/">Grillschule</a> und unserem Grillfachhandel vorbei.</small></p>
              <br />

            </div>
          </div>



        </div>
      </div >

    </div >
  )
}
export default HomePage

const subPageItems = [


  {
    title: "Geschäft in der Stadtmitte",
    text: `Besuchen Sie unser Geschäft in der Stadtmitte Grünbergs zwischen Markplatz und Diebsturm. Schlendern Sie durch die bunte Welt unserer Produkte. \n\nWir beraten Sie gerne!`,
    imageUrl: LadenImg,
    buttonText: "",
    uri: "/kontakt/",
  },

  {
    title: "Grillfachhandel und Grillschule",
    text: `Als Weber Experience World Store haben wir ganzjährig das komplette Weber Sortiment am Lager.

Nehmen Sie an einem unserer beliebten Grillkurse teil, hier grillen wir mit Ihnen gemeinsam auf den Weber Produkten und geben viele Tipps und Tricks rund ums Grillen preis.`,
    imageUrl: GrillschuleImg,
    buttonText: "Zur Wesbite",
    url: "http://gartenaroma.de/grillseminare/",
  },


]

