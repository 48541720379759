import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import TextPage from './TextPage'

function ImpressumPage() {
    return (
        <TextPage title="Impressum">

            <Helmet>
                <title>Impressum | Foto Halbich - Ihr Bilderprofi in Grünberg</title>
            </Helmet>


            <h2>Angaben gemäß § 5 TMG</h2>
            <p>Foto Halbich<br />
            Barfüßergasse 4<br />
            35305 Grünberg<br />
                <br />
            Ust.-Id: DE 250044540<br />
                <br />
            </p><h2>Kontakt</h2>
            <p>Telefon: <a href="tel:49640190147">(+49) 6401 - 90147</a><br />
                E-Mail: <a href="mailto:bilder@foto-halbich.de">bilder@foto-halbich.de</a>

            </p>
            <br />
            <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
            <p>Florian Halbich</p>


            <br />
            <h2>Datenschutzerklärung</h2>
            <p>Ihre Daten sind uns Wichtig! Hier finden Sie alle Informationen dazu, wie wir Ihre Daten schützen:</p>
            <Link to="/datenschutz/"><button>Datenschutzerklärung öffnen</button></Link>

        </TextPage>
    )
}

export default ImpressumPage
