import React from 'react'
import "./ServicePage.css"
import { Link } from "react-router-dom"
import QrCodeImg from "../assets/qr-code.png"
import TextPage from './TextPage'
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ServicePage() {
    return (
        <TextPage title={"Online-Foto-Service"}>
            <div className="ServicePage">

                <p><b>Bilder per APP von Zuhause aus bestellen.</b><br></br>
                Über den Google PlayStore oder den Apple AppStore die App <a>Smart Picture Creation</a> downloaden.<br />
                Nach der Installation den Aktivierungsschlüssel: <a><CopyToClipboard text={"EU VWTJ"}
                        onCopy={() => alert("Aktivierungsschlüssel kopiert. ✅")}>
                        <span>'<b className="red">EU VWTJ</b>' <i class="fal fa-copy"></i></span>
                    </CopyToClipboard > </a> eingeben und schon kann es losgehen.
                Format und Oberfläche auswählen, die Bilder auswählen, den Auftrag im WLAN übermitteln und bei uns abholen.</p>

                <div className="row mt-10">
                    <div className="col-12 col-lg-12 mb-5">
                        <h2>Desktop:</h2>
                        <div className="row">
                            <Item
                                url="https://www.smart-picture-system.eu/SmartPictureCreationWIN.exe"
                                name="Windows"
                                download
                                icon={<i class="fab fa-windows"></i>} />
                            <Item
                                url="https://apps.apple.com/de/app/smart-picture-creation/id1312715505?mt=12"
                                name="macOS"
                                download
                                icon={<i class="fab fa-apple"></i>} />
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mb-5">
                        <h2>Smartphone:</h2>
                        <div className="row mb-2">
                            <Item
                                url="https://play.google.com/store/apps/details?id=com.embarcadero.SmartPictureCreation"
                                name="Android"
                                icon={<i class="fab fa-google-play"></i>} />
                            <Item
                                url="https://appsto.re/de/-MGyfb.i"
                                name="iOS & iPad OS"
                                icon={<i class="fab fa-app-store-ios"></i>} />

                            <div className="col text-center">
                                <img style={{ width: "80%" }} src={QrCodeImg} /><br />
                                <small>Mit Smartphone-Kamera scannen</small>
                            </div>
                        </div>
                    </div>




                    {/* <div className="d-flex flex-row justify-content-between">
                    <div>
                    <a href="https://fotoservice.ringfoto.de/?delc=true" target="_blank">
                    <button>Websversion</button>
                    </a>
                    </div>
                    <div className="text-center" style={{ width: 240, opacity: 0.75, maxWidth: "100%" }}>
                    
                    </div>
                </div> */}

                    {/* <div className="mt-5" >
                    <Link to="/">
                    <button>Zurück zur Startseite</button>
                    </Link>
                </div> */}

                </div>
            </div>
        </TextPage>
    )
}
export default ServicePage

// online http://fotoservice.ringfoto.de/?delc=true

// https://play.google.com/store/apps/details?id=com.embarcadero.SmartPictureCreation&hl=de
// https://appsto.re/de/-MGyfb.i

function Item(props) {
    return (
        <a download={props.download}
            target={props.notBlank ? "" : "_blank"}
            href={props.url || "#"}
            className="col-12 col-md mb-2 ">

            <div className="item">
                {props.icon}
                <span className="name">
                    {props.name}
                </span>
            </div>

        </a>
    )
}