import React from 'react'
import TextPage from './TextPage'

function DatenschutzPage() {
    return (
        <TextPage title="Datenschutz">

            <div className="entry-summary">

                <h2>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen:</h2>
                <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:</p>
                <p>Foto Halbich &amp; Gartenaroma.de <br />
                Inh. Florian Halbich<br />
                Barfüßergasse 4<br />
                35305 Grünberg<br />
                Deutschland</p>

                <p>Tel.: 06401-90147</p>
                <p>E-Mail: Bilder@Foto-Halbich.de</p>
                <p>Website: www.foto-halbich.de</p>

                <br /><br />
                <h2>Datenschutzbeauftragter</h2>
                <p>Der Datenschutzbeauftragte des Verantwortlichen ist: <b>Florian Halbich</b></p>
                <p>
                    Barfüßergasse 4<br />
                35305 Grünberg<br />
                Deutschland</p>
                <p>Tel.: 06401-90147</p>
                <p><strong>&nbsp;</strong></p>
                <ol>
                    <li><strong> Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</strong></li>
                </ol>
                <p>Wir verarbeiten personenbezogene Daten unserer Kunden grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Kunden erfolgt regelmäßig nur nach Einwilligung der Kunden. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>
                <p>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.</p>
                <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p>
                <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;c DSGVO als Rechtsgrundlage.</p>
                <p>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d DSGVO als Rechtsgrundlage.</p>
                <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO als Rechtsgrundlage für die Verarbeitung.</p>
                <p>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p>
                <ol>
                    <li>a) Beim Besuch der Website</li>
                </ol>
                <p>Beim Aufrufen unserer Website [&nbsp;&nbsp; ] werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
                <ul>
                    <li>IP-Adresse des anfragenden Rechners,</li>
                    <li>Datum und Uhrzeit des Zugriffs,</li>
                    <li>Name und URL der abgerufenen Datei,</li>
                    <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
                    <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
                </ul>
                <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
                <ul>
                    <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
                    <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
                    <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                    <li>zu weiteren administrativen Zwecken (eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt)</li>
                </ul>
                <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</p>
                <p>Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein.</p>
                <ol>
                    <li>b) Bei Anmeldung für unseren Newsletter</li>
                </ol>
                <p>Sofern Sie nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang des Newsletters ist die Angabe einer E-Mail-Adresse ausreichend.</p>
                <p>Die Abmeldung ist jederzeit möglich, zum Beispiel über einen Link am Ende eines jeden Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit an [&nbsp;&nbsp; ] per E-Mail senden.</p>
                <ol>
                    <li>c) Bei Nutzung unseres Kontaktformulars</li>
                </ol>
                <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.</p>
                <p>Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.</p>
                <p>Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.</p>
                <p><strong>III. Weitergabe von Daten</strong></p>
                <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</p>
                <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
                <ul>
                    <li>Sie Ihre nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
                    <li>die Weitergabe nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
                    <li>für den Fall, dass für die Weitergabe nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;c DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>
                    <li>dies gesetzlich zulässig und nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>
                </ul>
                <ol>
                    <li><strong> Widerspruchsmöglichkeit</strong></li>
                </ol>
                <p>Der Kunde hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Kunden per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.</p>
                <p>Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht.</p>
                <ol>
                    <li><strong> Cookies</strong></li>
                </ol>
                <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</p>
                <p>In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.</p>
                <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
                <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der BeKundenfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>
                <p>Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff.&nbsp;5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.</p>
                <p>Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO erforderlich.</p>
                <p>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>
                <ol>
                    <li><strong> Analyse-Tools</strong></li>
                    <li>a) Tracking-Tools</li>
                </ol>
                <p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.</p>
                <p>Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.</p>
                <ol>
                    <li>i) Google Analytics<sup>1</sup></li>
                </ol>
                <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der&nbsp;<strong>Google Inc</strong>.&nbsp;<a href="https://www.google.de/intl/de/about/">(https://www.google.de/intl/de/about/)</a>&nbsp;(1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff.&nbsp;4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie</p>
                <ul>
                    <li>Browser-Typ/-Version,</li>
                    <li>verwendetes Betriebssystem,</li>
                    <li>Referrer-URL (die zuvor besuchte Seite),</li>
                    <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                    <li>Uhrzeit der Serveranfrage,</li>
                </ul>
                <p>werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).</p>
                <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.</p>
                <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein&nbsp;<strong>Browser-Add-on herunterladen und installieren</strong>&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=de">(https://tools.google.com/dlpage/gaoptout?hl=de)</a>.</p>
                <p>Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.</p>
                <p>Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der&nbsp;<strong>Google Analytics-Hilfe</strong>&nbsp;<a href="https://support.google.com/analytics/answer/6004245?hl=de">(https://support.google.com/analytics/answer/6004245?hl=de)</a>.</p>
                <ol>
                    <li>ii) Google Adwords Conversion Tracking</li>
                </ol>
                <p>Um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unserer Website für Sie auszuwerten, nutzen wir ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (siehe Ziffer&nbsp;4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere Webseite gelangt sind.</p>
                <p>Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Kunden bestimmte Seiten der Webseite des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Kunden auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.</p>
                <p>Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Kunden, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Kunden persönlich identifizieren lassen.</p>
                <p>Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „<a href="http://www.googleadservices.com/">www.googleadservices.com</a>“ blockiert werden. Googles Datenschutzbelehrung zum Conversion-Tracking finden Sie&nbsp;<strong>hier</strong>&nbsp;<a href="https://services.google.com/sitestats/de.html">(https://services.google.com/sitestats/de.html)</a>.</p>
                <p>iii) Matomo</p>
                <p>Wir verwenden die Open-Source-Software Matomo zur Analyse und statistischen Auswertung der Nutzung der Website. Hierzu werden Cookies eingesetzt (siehe Ziffer&nbsp;4). Die durch den Cookie erzeugten Informationen über die Websitenutzung werden an unsere Server übertragen und in pseudonymen Nutzungsprofilen zusammengefasst. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten und um eine bedarfsgerechte Gestaltung unserer Website zu ermöglichen. Eine Weitergabe der Informationen an Dritte erfolgt nicht.</p>
                <p>Es wird in keinem Fall die IP-Adresse mit anderen den Kunden betreffenden Daten in Verbindung gebracht. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).</p>
                <p>Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst. Klicken Sie&nbsp;<strong>hier</strong>&nbsp;<a href="https://matamo.org/docs/privacy/">(https://matamo.org/docs/privacy/)</a>, damit Ihr Besuch nicht mehr erfasst wird.</p>
                <p><strong>VII. Social Media Plug-ins</strong></p>
                <p>Wir setzen auf unserer Website auf Grundlage des Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;f DSGVO Social Plug-ins der sozialen Netzwerke Facebook, Twitter und Instagram ein, um unsere Kanzlei hierüber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. Die Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher unserer Webseite bestmöglich zu schützen.</p>
                <ol>
                    <li>a) Facebook</li>
                </ol>
                <p>Auf unserer Website kommen Social-Media Plugins von Facebook zum Einsatz, um deren Nutzung persönlicher zu gestalten. Hierfür nutzen wir den „LIKE“ oder „TEILEN“-Button. Es handelt sich dabei um ein Angebot von Facebook.</p>
                <p>Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden.</p>
                <p>Durch die Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt und dort gespeichert.</p>
                <p>Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „LIKE“ oder „TEILEN“-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen Server von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und Ihren Facebook-Freunden angezeigt.</p>
                <p>Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.&nbsp;B. um Ihre Nutzung unserer Website im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Kunden über Ihre Aktivitäten auf unserer Website zu informieren und um weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.</p>
                <p>Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem Facebook-Konto zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Facebook ausloggen.</p>
                <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den&nbsp;<strong>Datenschutzhinweisen</strong>&nbsp;<a href="https://www.facebook.com/about/privacy/">(https://www.facebook.com/about/privacy/</a>) von Facebook.</p>
                <ol>
                    <li>b) Twitter</li>
                </ol>
                <p>Auf unseren Internetseiten sind Plugins des Kurznachrichtennetzwerks der Twitter Inc. (Twitter) integriert. Die Twitter-Plugins (tweet-Button) erkennen Sie an dem Twitter-Logo auf unserer Seite. Eine Übersicht über tweet-Buttons finden Sie&nbsp;<strong>hier</strong>&nbsp;<a href="https://about.twitter.com/resources/buttons">(https://about.twitter.com/resources/buttons)</a>.</p>
                <p>Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, wird eine direkte Verbindung zwischen Ihrem Browser und dem Twitter-Server hergestellt. Twitter erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Twitter „tweet-Button“ anklicken, während Sie in Ihrem Twitter-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Twitter-Profil verlinken. Dadurch kann Twitter den Besuch unserer Seiten Ihrem BeKundenkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter erhalten.</p>
                <p>Wenn Sie nicht wünschen, dass Twitter den Besuch unserer Seiten zuordnen kann, loggen Sie sich bitte aus Ihrem Twitter-BeKundenkonto aus.</p>
                <p>Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von&nbsp;<strong>Twitter</strong>&nbsp;(<a href="https://twitter.com/privacy">(https://twitter.com/privacy)</a>.</p>
                <ol>
                    <li>c) Instagram</li>
                </ol>
                <p>Auf unserer Website werden auch sogenannte Social Plugins („Plugins“) von Instagram verwendet, das von der Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“) betrieben wird.</p>
                <p>Die Plugins sind mit einem Instagram-Logo beispielsweise in Form einer „Instagram-Kamera“ gekennzeichnet.</p>
                <p>Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Instagram her. Der Inhalt des Plugins wird von Instagram direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch diese Einbindung erhält Instagram die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Instagram-Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.</p>
                <p>Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Instagram in die USA übermittelt und dort gespeichert. Sind Sie bei Instagram eingeloggt, kann Instagram den Besuch unserer Website Ihrem Instagram-Account unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel das „Instagram“-Button betätigen, wird diese Information ebenfalls direkt an einen Server von Instagram übermittelt und dort gespeichert.</p>
                <p>Die Informationen werden außerdem auf Ihrem Instagram-Account veröffentlicht und dort Ihren Kontakten angezeigt.</p>
                <p>Wenn Sie nicht möchten, dass Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Instagram-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Instagram ausloggen.</p>
                <p>Weitere Informationen hierzu Sie in der <strong>Datenschutz-erklärung</strong>&nbsp;<a href="https://help.instagram.com/155833707900388">(https://help.instagram.com/155833707900388)</a>&nbsp;von Instagram.</p>
                <p><strong>VIII. Rechte der betroffenen Personen </strong></p>
                <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>
                <h3>a. Auskunftsrecht</h3>
                <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</p>
                <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</p>
                <p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</p>
                <p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
                <p>(7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
                <p>(8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.&nbsp;22 Abs.&nbsp;1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</p>
                <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art.&nbsp;46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.</p>
                <h3>b. Recht auf Berichtigung</h3>
                <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
                <h3>c. Recht auf Einschränkung der Verarbeitung</h3>
                <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</p>
                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</p>
                <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.&nbsp;21 Abs.&nbsp;1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</p>
                <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
                <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
                <h3>d. Recht auf Löschung</h3>
                <h4>aa) Löschungspflicht</h4>
                <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</p>
                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie legen gem. Art.&nbsp;21 Abs.&nbsp;1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.&nbsp;21 Abs.&nbsp;2 DSGVO Widerspruch gegen die Verarbeitung ein.</p>
                <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
                <p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</p>
                <p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art.&nbsp;8 Abs.&nbsp;1 DSGVO erhoben.</p>
                <h4>bb) Information an Dritte</h4>
                <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art.&nbsp;17 Abs.&nbsp;1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
                <h4>cc) Ausnahmen</h4>
                <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</p>
                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;h und i sowie Art.&nbsp;9 Abs.&nbsp;3 DSGVO;</p>
                <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art.&nbsp;89 Abs.&nbsp;1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</p>
                <p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                <h3>e. Recht auf Unterrichtung</h3>
                <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.</p>
                <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
                <h3>f. Recht auf Datenübertragbarkeit</h3>
                <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung auf einer Einwilligung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a DSGVO oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO oder auf einem Vertrag gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO beruht und</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
                <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p>
                <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
                <h3>g. Widerspruchsrecht</h3>
                <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
                <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>
                <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
                <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</p>
                <h3>h. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
                <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
                <h3>i. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
                <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>
                <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</p>
                <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</p>
                <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
                <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art.&nbsp;9 Abs.&nbsp;1 DSGVO beruhen, sofern nicht Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>
                <p>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</p>
                <h3>j. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
                <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.</p>
                <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art.&nbsp;78 DSGVO.</p>
                <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an&nbsp;[&nbsp;&nbsp; ]de.</p>
                <ol>
                    <li><strong> Datensicherheit</strong></li>
                </ol>
                <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>
                <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
                <p><strong>Aktualität und Änderung dieser Datenschutzerklärung</strong></p>
                <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.</p>
                <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter&nbsp;[&nbsp;&nbsp; ]&nbsp;von Ihnen abgerufen und ausgedruckt werden.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>


        </TextPage>
    )
}
export default DatenschutzPage