import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import TextPage from './TextPage'

function KontaktPage() {
    return (
        <TextPage title="Kontakt">

            <p>Sie haben Fragen an uns, möchten mehr über unsere Angebote erfahren oder uns einfach nur Ihre Meinung sagen? <br />Bitte kontaktieren Sie uns. Wir sind gerne für Sie da.</p>


            <br></br>
            <a href="mailto:bilder@foto-halbich.de">
                <h2>E-Mail: </h2>
                <p>
                    <u>bilder@foto-halbich.de</u>
                </p>
            </a>

            <br></br>
            <a href="tel:49640190147">
                <h2>Telefon:</h2>
                <p>
                    <u>06401 90147</u>
                </p>
            </a>

            <br></br>
            <a>
                <CopyToClipboard text={"Foto Halbich, Barfüßergasse 4, 35305 Grünberg"}
                    onCopy={() => alert("Anschrift kopiert. ✅")}>
                    <div>
                        <h2>Anschrift:</h2>
                        <p>
                            Foto Halbich<br />
                Barfüßergasse 4<br />
                35305 Grünberg<br />
                        </p>
                    </div>
                </CopyToClipboard>
            </a>

        </TextPage>
    )
}

export default KontaktPage
