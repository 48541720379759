import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import "./TextPage.css"

function TextPage(props) {
    return (
        <div className="TextPage">
            <div className="container ">
                <h1> <Link to={"/"}><i class="fas fa-angle-left"></i></Link> {props.title}</h1>

                <div className="">
                    {props.children}
                </div>
            </div>

            <Helmet>
                <title>{props.title.toString() + " | Foto Halbich - Ihr Bilderprofi in Grünberg"}</title>
            </Helmet>
        </div>
    )
}

export default TextPage
