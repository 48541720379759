import React from 'react'
import { Link } from 'react-router-dom'
import PowerdBy from './PowerdBy'

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="d-flex flex-row p-5 flex-wrap">


                    <div className="col-12 col-lg-3 mb-5">
                        <h3>Öffnungszeiten:</h3>
                        <p>
                            <b>Mo-Fr:</b> 9:00 - 18:00 Uhr<br />
                            <b>Sa:</b> 9:30 - 14:00 Uhr<br />
                            <small>
                                1. Samstag im Monat 9:30 - 16:00 Uhr
                            </small>
                        </p>
                    </div>


                    <div className="col-12 col-lg-3 mb-5">
                        <h3>Mehr von uns:</h3>
                        <div className="d-flex flex-column">
                            <a href="https://www.instagram.com/fotohalbich/" target="_blank"><i class="fab fa-instagram"></i> fotohalbich</a>
                            <a href="https://goo.gl/maps/5Jcikh6pU5tf8SVA9" target="_blank">Google Maps</a>
                            <a href="https://maps.apple.com/?address=Barfüßergasse 4, 35305 Grünberg, Deutschland&auid=15588378627476452733&ll=50.591305,8.959754&lsp=9902&q=FOTO HALBICH&_ext=ChkKBQgEEIEBCgQIBRADCgQIBhAWCgQIChAAEiYpTJDHsxxLSUAxrY04icXnIUA5ymXtD0NMSUBBb0RwNwTvIUBQBA==" target="_blank">Apple Karten</a>

                        </div>
                    </div>


                    <div className="col-12 col-lg-3 mb-5">
                        <h3>Alle Seiten:</h3>
                        <div className="d-flex flex-column">

                            <Link to="/foto-service/" className="mb-2 mt-1">
                                <button>Zum Bilderservice</button>
                            </Link>
                            <Link to="/kontakt/">Kontakt</Link>
                            <a target="_blank" href="https://gartenaroma.de">Grillfachhandel & Grillschule</a>
                        </div>
                    </div>


                    <div className="col-12 col-lg-3 mb-5">
                        <h3>Rechtliches:</h3>
                        <div className="d-flex flex-column">
                            <Link to="/kontakt/">Kontakt</Link>
                            <Link to="/impressum/">Impressum</Link>
                            <Link to="/datenschutz/">Datenschutzerklärung</Link>
                        </div>
                    </div>


                </div>
                <div className="d-md-flex flex-row align-items-center justify-content-between mt-md-5 p-5">
                    <small>© {(new Date().getFullYear())} Foto Halbich – Alle Rechte vorbehalten.</small>
                    <br />
                    <br />

                    <div>
                        <PowerdBy />
                    </div>
                </div>
            </div>
        </footer >

    )
}

export default Footer
