import React, { useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'

import LogoImg from "../assets/logo.png"
import WeberlogoImg from "../assets/weber.jpg"

import "./NavTop.css"

function NavTop() {

    const [MenuIsOpen, setMenuIsOpen] = useState(false);

    return (
        <div className="container">
            <div className="nav-top">

                <Link className="col-12 col-lg" to="/">
                    <img className="logo" src={LogoImg}></img>
                </Link>

                <div className="d-block d-lg-none menu-toggle" onClick={() => setMenuIsOpen(!MenuIsOpen)}>
                    {MenuIsOpen ?
                        <div>
                            <span>Menü schließen</span> <i class="fas fa-times"></i>
                        </div> :
                        <div>
                            <span>Menü öffnen</span> <i class="fas fa-bars"></i>
                        </div>
                    }
                </div>


                <div className={"menu-content " + MenuIsOpen}>

                    <div className="col-12 col-lg links flex-lg-row flex-column">
                        <div className="d-flex flex-lg-row flex-column">
                            <Switch>

                                <Route path="/" exact>
                                    <Link to="/" className="active"> Home</Link>

                                    <Link to="/foto-service/">Bilderservice</Link>
                                </Route>

                                <Route path={["/online-foto-service", "/foto-service/", "/fotoservice/"]}>
                                    <Link to="/"> Home</Link>
                                    <Link to="/foto-service/" className="active">Bilderservice</Link>
                                </Route>

                            </Switch>
                        </div>
                        <div className="split d-none d-lg-inline"></div>
                        <a href="http://gartenaroma.de/" target="_blank" className="d-flex flex-row">
                            <img className="weberLogo mr-2" src={WeberlogoImg} />
                            <small className="d-block d-lg-inline">Grillfachhandel & Grillschule</small>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NavTop
