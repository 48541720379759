import React from 'react'
import { Link } from 'react-router-dom'

function SubPageItem(props) {

    const content = <div className="row">
        <div className="col-12 col-lg-6">
            <img className="w-100" src={props.imageUrl}></img>
        </div>
        <div className="col-12 col-lg-6 p-4">
            <h2>{props.title}</h2>
            <p>{props.text}</p>
            {
                props.buttonText ?
                    <button>{props.buttonText}</button>
                    :
                    null
            }
        </div>
    </div>

    return (
        <div className="col-12 mb-5">
            {props.uri ?
                <Link to={props.uri}>
                    {content}
                </Link>
                :
                <a href={props.url} target="_blank">
                    {content}
                </a>
            }
        </div>

    )
}

export default SubPageItem
